import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import useGallery from "../hooks/use-gallery"
import { LightgalleryItem } from "react-lightgallery";
import "./style.css"

const Gallery = () => {
  const nodes = useGallery()

  return (
    <div className="instagram-grid-gallery">
      {nodes.map((edge) => {
        
        return (
          <LightgalleryItem src={edge.node.childImageSharp.gatsbyImageData.images.fallback.src}>
            <div key={edge.node.relativePath} style={{ height: "100%", width: "100%" }} className="instagram-link-styles">
              <div className="instagram-overlay" />
                <GatsbyImage
                  image={edge.node.childImageSharp.gatsbyImageData} alt=""
                  objectFit="fill"
                  imgStyle={{ height: "100%", width: "100%", objectFit: "cover" }}
                  style={{ height: "100%", width: "100%" }}
                />
            </div>
          </LightgalleryItem>
        )
      })}
    </div>
  )
}

export default Gallery