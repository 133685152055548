import { useStaticQuery, graphql } from "gatsby"

const useMaps = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 90)
            }
          }
        }
      }
    }
  `)
  
  const mapImages = data.images.edges
    .filter(edge => edge.node.relativePath.indexOf("maps/") !== -1);

  return mapImages;
}

export default useMaps