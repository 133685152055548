import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import useWineries from "../hooks/use-wineries";
import "./style.css";
import getPathFromName from "../../utils/get-path-from-name";

const Wineries = () => {
  const nodes = useWineries();

  return (
    <div className="instagram-grid">
      {nodes
        .map((edge) => {
          const path = edge.node.relativePath;
          const regex = /\/(.+)\//g;
          const match = regex.exec(path);
          const fullName = match[1];
          const dashIndex = fullName.indexOf("-");
          const title = fullName.substr(0, dashIndex).trim();
          const subtitle = fullName.substring(dashIndex + 1).trim();
          return {
            title,
            subtitle,
            image: edge.node.childImageSharp.gatsbyImageData,
            path,
          };
        })
        .sort((a, b) => {
          if (getPathFromName(a.title) < getPathFromName(b.title)) {
            return -1;
          }
          if (getPathFromName(a.title) > getPathFromName(b.title)) {
            return 1;
          }
          return 0;
        })
        .map(({ title, subtitle, image, path }) => {
          return (
            <a
              key={path}
              className="instagram-link-styles"
              href={`/${getPathFromName(title)}`}
              style={{
                maxHeight: "450px",
              }}
            >
              <div className="instagram-overlay" />
              <GatsbyImage
                image={image}
                alt=""
                objectFit="fill"
                imgStyle={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  maxHeight: "450px",
                }}
                style={{ height: "100%", width: "100%" }}
              />
              <div className="instagram-content">
                <div className="instagram-title">{title}</div>
                <div className="instagram-bottom">
                  <span>{subtitle}</span>
                </div>
              </div>
            </a>
          );
        })}
    </div>
  );
};

export default Wineries;
