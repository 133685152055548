export default function getPathFromName(name: string) {
  return name
    .toLowerCase()
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ő/g, "o")
    .replace(/ö/g, "o")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ű/g, "u")
    .replace(/ü/g, "u")
    .replace("&", "")
    .replace("-", "")
    .replace("  ", " ")
    .replace(/ /g, "-");
}