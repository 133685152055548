import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./style.css";
import useMaps from "../hooks/use-maps";

const Maps = () => {
  const nodes = useMaps();

  return (
    <div className="instagram-grid">
      {nodes
        .sort((a, b) => a.node.relativePath.localeCompare(b.node.relativePath))
        .map((edge) => {
          console.log(edge);

          const path = edge.node.relativePath;
          const regex = /\/(.+)\./g;
          const match = regex.exec(path);
          const title = match[1];

          return (
            <a
              key={edge.node.relativePath}
              className="instagram-link-styles"
              target="__blank"
              href={
                edge.node.childImageSharp.gatsbyImageData.images.fallback.src
              }
            >
              <div className="instagram-overlay" />
              <GatsbyImage
                image={edge.node.childImageSharp.gatsbyImageData}
                alt=""
                objectFit="fill"
                imgStyle={{ height: "100%", width: "100%", objectFit: "cover" }}
                style={{ height: "100%", width: "100%" }}
              />
              <div className="instagram-content">
                <div className="instagram-title" style={{ color: "#000" }}>
                  {title}
                </div>
              </div>
            </a>
          );
        })}
    </div>
  );
};

export default Maps;
